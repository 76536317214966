import React from "react";

export default function Home() {
  return (
    <>
      <h1>Home Page</h1>
      <p>Welcome to the home page</p>
    </>
  );
}
